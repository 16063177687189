import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OnboardingSelectorDirective } from '@atlas-workspace/shared/directives';
import { IHeaderDropdown } from '@atlas-workspace/shared/models';
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-grouped-drop-down',
  templateUrl: './grouped-drop-down.component.html',
  styleUrls: ['./grouped-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, OnboardingSelectorDirective, NgbDropdownModule],
})
export class GroupedDropDownComponent {
  @Input() listOfMenu!: IHeaderDropdown[];
  @Input() selectedItem!: IHeaderDropdown;
  @Output() private readonly changeInfo = new EventEmitter<IHeaderDropdown>();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  selectProjectAndUnit(event: MouseEvent, info: IHeaderDropdown): void {
    event.stopPropagation();
    this.changeInfo.emit(info);
  }

  public navigateHome(): void {
    const projectId = this.route.snapshot.params.projectId;
    const unitId = this.route.snapshot.params.unitId;
    void this.router.navigate([`base-layout/project/${projectId}/unit/${unitId}/home/view`]);
  }
}
