<div class="d-flex align-items-center">
  <img
    class="header__mobile-view drop-down__logo cursor-pointer"
    src="/assets/journey-client-logo.svg"
    width="89px"
    height="20px"
    alt="Logo"
    (click)="navigateHome()"
  />

  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    class="drop-down__wrapper"
    tabindex="0"
    [class.open]="dropdown._open"
    [class.single]="listOfMenu.length === 1"
    [atlOnboardingSelector]="3"
    display="static"
  >
    <div ngbDropdownToggle class="drop-down__title" id="grouped-drop-down">
      <div
        id="cy-drop-menu-project"
        [class.drop-down__name--no-pointer]="listOfMenu?.length === 1"
        class="drop-down__selected-item ellipsis"
      >
        <h2 class="drop-down__name fz-16 lh-24 fw-500 ellipsis">
          {{ selectedItem ? selectedItem.projectName : ('Shared.Entity.No_projects_yet' | translate) }}
        </h2>
        <span class="drop-down__dot"> · </span>
        <h3 class="drop-down__unit-name fz-12 lh-16 fw-600 ellipsis">
          {{ selectedItem ? selectedItem.unitName : ('Shared.Entity.No_unit_yet' | translate) }}
        </h3>
      </div>
      <div *ngIf="listOfMenu?.length > 1" class="drop-down__arrow-wr">
        <div
          [class.rotate]="dropdown._open"
          [class.open]="dropdown._open"
          class="drop-down__arrow"
        ></div>
      </div>
    </div>

    <div
      ngbDropdownMenu
      aria-labelledby="grouped-drop-down"
      id="cy-drop-menu-project-list"
      class="drop-down__wrapper-selecting"
      *ngIf="dropdown._open && listOfMenu.length > 1"
    >
      <div class="drop-down__selection-container v-scrollbar">
        <ng-container *ngFor="let value of listOfMenu; let index = index">
          <div
            ngbDropdownItem
            class="drop-down__selection-item"
            [class.selected]="value.projectId === selectedItem.projectId && value.unitId === selectedItem.unitId"
            (click)="selectProjectAndUnit($event, value)"
          >
            <div class="drop-down__selection-item-project ellipsis">{{ value.unitName }}</div>
            <div class="drop-down__selection-item-unit ellipsis">{{ value.projectName }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
